import React, { Component } from "react";
import Page from "../containers/page";
import Isvg from "react-inlinesvg";

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledDropdown,
  Input,
} from "reactstrap";

var striptags = require("striptags");

class TermsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState({
          ...data,
        });
      });
    }

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    }
  }

  render() {
    return (
      <div>
        <section className="terms-page" id="terms">
          <div className="terms-logo">
            <img src="images/logo-uslovi.png" alt="" />
          </div>
          <div className="terms-content">
            <div className="terms-col">
              <h1>
                Koka-kolina nagradna igra “U fudbalu uživaj nagrade osvajaj”
              </h1>
              <h2 className="move-down">
                OBAVEŠTENJE O OBRADI PODATAKA O LIČNOSTI
              </h2>
              <p>
                Privredno društvo Kreativa Unlimited doo Beo-grad, sa registrovanim sedištem u ul. Ušće bb, Novi Beograd, (u daljem tekstu
                <span className="bold"> „Društvo“)</span> želelo bi da, u svojstvu rukovaoca podataka, prikupi od Vas i obrađuje određene podatke o ličnosti t.j. Vaše ime, prezime, broj lične karte ili pasoša, adresu stanovanja i broj mobilnog tele-fona, jedinstveni matični broj, a u okviru Coca-Cola Hellenic Bottling Company- Srbija Industri-ja bezalkoholnih pića d.o.o.  promotivne ak-tivnosti pod nazivom <span className="bold">
                  “U fudbalu uživaj nagrade osvajaj”
                </span> za koju Društvo realizuje kreiranje liste dobitnika za distribuciju poklona u periodu od 22.05.2024. do 03.07.2024. U tom cilju Vas obaveštavamo vezano za obradu Vaših podataka o ličnosti, a što je u skladu sa Zakonom o zaštiti podataka o ličnosti ("Sl. glasnik RS", br. 97/2008, 104/2009 - dr. zakon, 68/2012 - odluka US i 107/2012 ) (u daljem tekstu
                <span className="bold"> „Zakon“</span>) uslov za Vaše davanje pristanka za obradu ličnih podataka, a koje dajete prihvatanjem Pravilnika programa lojalnosti.
              </p>

              <h2>1. PODACI KOJI BI SE PRIKUPLJALI I OBRAĐIVALI</h2>
              <p>
                Društvo, u cilju realizacije promotivne aktivnosti pod nazivom  <span className="bold"> „ U fudbalu uživaj nagrade osvajaj “  </span> i njene svrhe dalje objašnjene u tekstu ovog obaveštenja namerava da, ukoliko pristanete, od Vas prikuplja i obrađuje sledeće podatke o ličnosti:
              </p>
              <ul>
                <li>
                  <span>a)</span> Ime i prezime
                </li>
              </ul>
            </div>
            <div className="terms-col col-space">
              <ul>
                <li>
                  <span>b)</span> Broj lične karte ili pasoša
                </li>
                <li>
                  <span>c)</span>	JMBG
                </li>
                <li>
                  <span>d)</span> Broj telefona
                </li>
                <li>
                  <span>e)</span> E-mail adresu
                </li>
                <li>
                  <span>f)</span> Adresu korisnika
                </li>
              </ul>

              <h2>2. SVRHA PRIKUPLJANJA I DALJE OBRADE</h2>
              <p>
                Naime, svrha prikupljanja i obrade iznad navesenih podataka je u tome da nakon sti-canja prava na poklon o čemu ste dobili obaveštenje i ID broj poklona na email, poručite svoju nagradu  na web sajtu
                <span>
                  <a href="https://www.porucipoklon.rs/">
                    <span className="bold"> www.porucipoklon.rs </span>
                  </a>
                </span>
                pri čemu je potrebno ostaviti podatke kako bi se poklon dostavio na adresu dobitnika. Potvr-da o pravilno unesenim podacima za dostavu vam stiže na email adresu koju je dobitnik uneo prilikom popunjavanja podataka.
              </p>

              <h2>NAČIN KORIŠĆENJA PODATAKA</h2>
              <p>
                Društvo će prikupljati Vaše lične podatke t.j. Vaš broj telefona, ime, prezime, email i broj lične karte tako što će isti biti pohranjen, nakon Vašeg pristanka prihvatanjem Pravilnika pro-grama lojalnosti, na softveru web sajta i u na-logu za isporuku poklona samo i isključivo u cilju realizacije dostave poklona.
              </p>

              <h2>INFORMACIJE O KORISNICIMA PODATAKA</h2>
              <p>
                Društvo će davati na uvid Vaše lične podatke t.j. Vaš broj telefona, email, ime, prezime I broj lične
              </p>
            </div>
            <div className="terms-col">
              <p>
                karte isključivo svojim zaposlenima i zaposlen-ima Pošte i programerima angažovanim na upravljanju softverom aplikacije samo za svrhe obrade kako je gore opisana, te radi ispunja-vanja, na zakonu zasnovanih, zahteva nadležnih državnih  ili organa lokalne samouprave.
              </p>

              <h2>3. OBAVEZNOST I PRAVNI OSNOV</h2>
              <p>
                Vaš dati pristanak prihvatanjem Pravilnika pro-grama lojalnosti predstavlja pravni osnov za prikupljanje i obradu Vaših ličnih podataka  kako je gore opisano. Davanje saglasnosti nije obavezno već dobrovoljno.
              </p>

              <h2>4. OPOZIV</h2>
              <p>
                Vaš pristanak za obradu ličnih podataka možete opozvati rukovaocu, pismeno ili usme-no na zapisnik. Obrada ličnih podataka na način kako je gore opisano je nedozvoljena posle opoziva pristanka. U slučaju opoziva pristanka dužni ste da Društvu nadoknadite sve opravdane troškove i štetu koji nastanu u slučaju opoziva, ukoliko nastanu, ali samo ukoli-ko su ispunjeni uslovi predviđeni propisima koji uređuju odgovornost za štetu.
              </p>
              <h2>5. NEDOZVOLJENA OBRADA</h2>
              <h3>5.1. Nedozvoljena obrada</h3>
              <p>Obrada Vaših ličnih podataka nije dozvoljena ako:</p>
            </div>
          </div>

          <div className="terms-adress">
            <p>
              Adresa: Ušće bb, 11073 Novi Beograd, Poštanski fah 4. PIB: 105695624
            </p>
          </div>

          <div className="terms-content">
            <div className="terms-col">
              <ul>
                <li>
                  <span>a)</span>	fizičko lice nije dalo pristanak za obradu, odnosno ako se obrada vrši bez zakonskog ovlašćenja;
                </li>
                <li>
                  <span>b)</span> se vrši u svrhu različitu od one za koju je određena, bez obzira da li se vrši na osnovu pristanka lica ili zakonskog ovlašćenja za obradu bez pristanka, osim ako se vrši u svrhu prikupljanja sredstava za humanitarne potrebe;
                </li>
                <li>
                  <span>c)</span> svrha obrade nije jasno određena, ako je izmenjena, nedozvoljena ili već ost-varena;
                </li>
                <li>
                  <span>d)</span> je lice na koje se podaci odnose od-ređeno ili odredivo i nakon što se ost-vari svrha obrade;
                </li>
                <li>
                  <span>e)</span>	je način obrade nedozvoljen;
                </li>
                <li>
                  <span>f)</span> je podatak koji se obrađuje nepotreban ili nepodesan za ostvarenje svrhe obrade;
                </li>
                <li>
                  <span>g)</span> su broj ili vrsta podataka koji se obrađuju nesrazmerni svrsi obrade;
                </li>
                <li>
                  <span>h)</span> je podatak neistinit i nepotpun, odnos-no kada nije zasnovan na verodosto-jnom izvoru ili je zastareo.
                </li>
              </ul>
              <p>
                Imate pravo na obaveštenje, uvid i kopiju, osim u slučajevim ograničenja iz člana 23 Zakona.
              </p>
              <h3>5.2. Pravo na obaveštenje o obradi</h3>
              <p>
                Imate pravo da zahtevate od Društva kao rukovaoca da Vas istinito i potpuno obavesti, u roku od 15 dana od prijema zahteva o tome:
              </p>
            </div>
            <div className="terms-col col-space">
              <ul>
                <li>
                  {" "}
                  <span>a)</span> da li rukovalac obrađuje podatke i koju radnju
                  obrade vrši;
                </li>
                <li>
                  {" "}
                  <span>b)</span> koje podatke obrađuje;
                </li>
                <li>
                  <span>c)</span> od koga su prikupljeni podaci, odnosno ko je
                  izvor podataka;
                </li>
                <li>
                  {" "}
                  <span>d)</span> u koje svrhe obrađuje podatke;
                </li>
                <li>
                  {" "}
                  <span>e)</span> po kom pravnom osnovu obrađuje podatke;
                </li>
                <li>
                  <span>f)</span> u kojim zbirkama podataka se nalaze podaci;
                </li>
                <li>
                  {" "}
                  <span>g)</span> ko su korisnici podataka;
                </li>
                <li>
                  {" "}
                  <span>h)</span> koje podatke, odnosno koje vrste podataka
                  koriste;
                </li>
                <li>
                  {" "}
                  <span>i)</span> u koje svrhe se koriste podaci;
                </li>
                <li>
                  {" "}
                  <span>j)</span> po kom pravnom osnovu koristi podatke;
                </li>
                <li>
                  {" "}
                  <span>k)</span> kome se podaci prenose;
                </li>
                <li>
                  <span>l)</span> koji podaci se prenose;
                </li>
                <li>
                  <span>m)</span> u koje svrhe se podaci prenose;
                </li>
                <li>
                  <span>n)</span> po kom pravnom osnovu se podaci prenose;
                </li>
                <li>
                  <span>o)</span> u kom vremenskom periodu se podaci obrađuju.
                </li>
              </ul>
              <p>Zahtev podnosite rukovaocu pismeno.</p>
              <h3>5.3. Pravo na uvid i kopiju</h3>
            </div>
            <div className="terms-col">
              <p>
                Imate pravo da Vam rukovalac stavi na uvid Vaše lične podatke i obezbedi kopiju podatka u roku od 30 dana od dana prijema zahteva, osim ukoliko isti nisu već uništeni u skladu sa tačkom 8 ovog obaveštenja. Dužni ste da snosite nužne troškove izrade i predaje kopije podatka.
                <br />
                <br />
                Zahtev podnosite rukovaocu pismeno.
              </p>
              <h3>5.4. Prava povodom izvršenog uvida</h3>
              <p>
                Imate pravo da od rukovaoca zahtevate is-pravku, dopunu, ažuriranje, brisanje podataka, kao i prekid i privremenu obustavu obrade.
                <br />
                <br />
                Pravo na brisanje podataka imate ako:
              </p>
              <ul>
                <li>
                  <span>a)</span> svrha obrade nije jasno određena;
                </li>
                <li>
                  <span>b)</span> je svrha obrade izmenjena, a nisu ispunjeni
                  uslovi za obradu za tu izmenjenu svrhu;
                </li>
                <li>
                  <span>c)</span> je svrha obrade ostvarena, odnosno podaci više
                  nisu potrebni za ostvarivanje svrhe;
                </li>
                <li>
                  <span>d)</span> je način obrade nedozvoljen;
                </li>
                <li>
                  <span>e)</span> podatak spada u broj i vrstu podataka čija je
                  obrada nesrazmerna svrsi;
                </li>
                <li>
                  <span>f)</span> je podatak netačan, a ne može se putem
                  ispravke zameniti tačnim;
                </li>
                <li>
                  <span>g)</span> se podatak obrađuje bez pristanka ili
                  ovlašćenja zasnovanog na zakonu i u drugim slučajevima kada se
                  obrada ne može vršiti u skladu sa odredbama zakona.
                </li>
              </ul>
            </div>
          </div>

          <div className="terms-logo">
            <img src="images/logo-uslovi.png" alt="" />
          </div>
          <div className="terms-content">
            <div className="terms-col">
              <p>
                Imate pravo na prekid i privremenu obustavu obrade, ako ste osporili tačnost, potpunost i ažurnost podataka, kao i pravo da se ti podaci označe kao osporeni, dok se ne utvrdi njihova tačnost, potpunost i ažurnost.
                <br />
                <br />
                Zahtev za ostvarivanje prava povodom izvršenog uvida podnosite rukovaocu pismeno. Rukovalac će odlučiti o Vašem podnetom zahtevu u roku od 15 dana od dana prijema i o tome Vas obavestiti.
              </p>
              <h3>5.5. Žalba povereniku</h3>
              <p>
                Ukoliko Vam rukovalac ne dostavi obaveštenje o obradi, ne omogući uvid ili kopiju, u gore navedenom roku, možete podneti žalbu Pov-ereniku za informacije od javnog značaja i zaštitu podataka o ličnosti, u roku od 15 dana po isteku gore navedenog roka.
                <br />
                <br />
                Ukoliko Vam rukovalac odbaci ili odbije zahtev za ostvarivanje prava povodom izvršenog uvida možete podneti žalbu Povereniku za informacije od javnog značaja i zaštitu po-dataka o ličnosti, u roku od 15 dana od dana prijema rešenja rukovaoca o odbačaju ili od-bijanju.
              </p>
              <h2>6. MERE ZAŠTITE </h2>
              <p>
                Vaši lični podaci će odgovarajuće biti zaštićeni od zloupotreba, uništenja, gubitka, neovlašćenih promena ili pristupa.
                <br />
                <br />
                Rukovalac preduzima tehničke, kadrovske i organizacione mere zaštite podataka, u skladu sa 
              </p>
            </div>
            <div className="terms-col col-space">
              <p>
              utvrđenim standardima i postupcima, a koje su potrebne da bi se podaci zaštitili od gubitka, uništenja, nedopuštenog pristupa, promene, objavljivanja i svake druge zloupotrebe.
                <br />
                <br />
                Podaci se čuvaju u računarskom sistemu, koji je osiguran sistemom lozinki za autorizaciju i iden-tifikaciju korisnika podataka.
              </p>
              <h2>7. OSTALE BITNE INFORMACIJE</h2>
              <p>
              Vaši lični podaci t.j. Vaš broj telefona,email, ime, prezime i broj lične karte će biti trajno uklonjeni i uništeni sa softvera aplikacije bez odlaganja nakon završetka promotivne ak-tivnosti.
                <br />
                <br />
                Ukoliko imate pitanja ili zahteva vezano za obradu Vaših ličnih podataka, kontakt je kako sledi:
                <br />
              </p>
              <span>Kreativa Unlimited doo Beograd</span>
              <span>Ušće bb</span>
              <span>Poštanski fah 4</span>
              <span>11070 Novi Beograd</span>
              <span>Kontakt osoba: Jovana Medarević</span>
              <span>E mail: jmedarevic@kreativa.co.rs</span>
            </div>
            <div className="terms-col"></div>
          </div>
          <div className="terms-adress">
            <p>
              Adresa: Ušće bb, 11073 Novi Beograd, Poštanski fah 4. PIB: 105695624
            </p>
          </div>
        </section>
      </div>
    );
  }
}

export default Page(TermsPage);
