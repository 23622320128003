import HomePage from './views/homePage/homePage';
import WelcomePage from './views/welcomePage';
import { API_ENDPOINT } from './constants';
import TermsPage from './views/termsPage';
import PopUp from './views/popUp';
import Thank from './views/thank';

const linkTranslates = {
    'en': {
        '/': '/en',
        '/welcome': '/welcome',
        '/terms': '/terms',
        '/confirmPage': '/confirmPage',
        '/thank-you': '/thank-you'
    },
}
function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}
export const routes = [
    {
        path: "/",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Coca cola',
                description: ''.translate(lang),
                // 'og:image': 'http://localhost:4000/uploads/share.png'
            }
        },
        component: HomePage,
        loadData: [
            // (fetchFunction, lang, match, query) => {


            //     return fetchFunction(API_ENDPOINT + '/data/postOffice', {
            //         method: 'GET',
            //         headers: {
            //             // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },

            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 posts: result
            //             }



            //     })


            // },
            // (fetchFunction, lang, match, query) => {


            //     return fetchFunction(API_ENDPOINT + '/data/cities', {
            //         method: 'GET',
            //         headers: {
            //             // 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            //             'content-type': 'application/json'
            //         },

            //     }).then(parseJSON).then(({ result, status }) => {
            //         if (status >= 200 && status < 300)
            //             return {
            //                 cities: result
            //             }



            //     })


            // },

        ]
    },
    {
        path: "/welcome",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Coca cola',
                description: ''.translate(lang),
                // 'og:image': 'http://localhost:4000/uploads/share.png'
            }
        },
        component: WelcomePage,
        loadData: []
    },
    {
        path: "/terms",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Coca cola',
                description: ''.translate(lang),
                // 'og:image': 'http://localhost:4000/uploads/share.png'
            }
        },
        component: TermsPage,
        loadData: []
    },
    {
        path: "/confirmPage",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Test',
                description: 'Test'.translate(lang),
                // 'og:image': 'http://localhost:4000/uploads/share.png'
            }
        },
        component: PopUp,
        loadData: []
    },
    {
        path: "/thank-you",
        generateSeoTags: (data, lang) => {
            return {
                title: 'thank-you',
                description: 'thank-you'.translate(lang),
                // 'og:image': 'http://localhost:4000/uploads/share.png'
            }
        },
        component: Thank,
        loadData: []
    },
];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}