import React, { Component } from "react";
import Page from "../containers/page";

// import ball from "../assets/ball.png";
// import pokloni from "../assets/back.png";

var striptags = require("striptags");

class WelcomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    /*
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        */

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    }
  }

  render() {
    return (
      // <div className='welcome-page'>
      //     <div className='welcome-page-container'>
      //         <div className='welcome-upper'>
      //             <div className='welcome-upper-container'>

      //                 <div className="heading">
      //                     <h6 className="heading-info">Pravilno ste uneli podatke i poručili svoje nagrade.</h6>
      //                     <div className="heading-text">Za Srbiju navijaj, <br/>nagrade osvajaj</div>
      //                 </div>
      //             </div>
      //             <img className="ball-img" src={ball} />

      //         </div>
      //         <div className="welcome-lower">
      //             <div className='welcome-lower-container'>
      //                 <h6 className="heading-info" >Za još nagrada sakupljajte kodove.</h6>
      //                 <p className="heading-info">Napomene vezane za preuzimanje fizičkih nagrada i vaučera pronađite u mejlu potvrde i u Pravilniku na
      //                 sajtu <span><a href="https://www.coca-cola.rs/">www.coca-cola.rs</a></span>.
      //                 Posetite sajt za više informacija.
      //                 </p>
      //                 <h5>©2022 The Coca-Cola Company.</h5>
      //             </div>
      //         </div>
      //     </div>
      // </div>

      <div className="welcome-page">
        {/* <div className="heading"> */}
      

   
        <h6 className="heading-info-top">
          Čestitamo, pravilno ste uneli podatke i porucili svoju nagradu!
        </h6>
        <div className="img-container">
          {/* <img className="ball-img" src={pokloni} /> */}

          <div className="heading-text">
            <div>POKRENI
            PRAZNIČNU
            <span>MAGIJU</span>
            <p>
              uz Coca-Cola
              kolekciju
            </p>
         </div>
         </div>
          </div>
   

        {/* <p className="heading-info" style={{fontSize: 24}}>
            Promocija traje od 07.08 do 03.09.2023 godine ili do isteka zaliha. <br />
            
          </p>
          <p className="heading-info-2" style={{fontSize: 24}}>
            Napomene vezane za preuzimanje fizičkih nagrada i vaučera pronađite u mejlu potvrde i u Pravilniku
          </p> */}
        <h5 style={{ marginTop: 50, marginLeft: 50 }}>©2023 The Coca-Cola Company.</h5>
        {/* </div> */}
      </div>
    );
  }
}

export default Page(WelcomePage);
