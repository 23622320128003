

import React, { Component } from 'react';
import Page from '../../containers/page';
import Isvg from 'react-inlinesvg'
import headSvg from '../../assets/header.svg'


class StartGame extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='home-page-startup'>
                <div className='home-page-startup-content'>
                    <div className='home-page-content-header'>
                        <Isvg src={headSvg} />
                        <h3>
                            Poručivanje poklona je završeno
                            <br />
                            31.07.2024.
                        </h3>
                    </div>
                    <div className='home-page-startup-content-header'>
                        {/* <p>Tvoja omiljena kolekcija te čeka.</p>
                        <p>
                            Molimo vas da prilikom popunjavanja proverite tačnost podataka,
                            <br />
                            u suprotnom organizator zadržava pravo da ne isporuči poklone
                        </p> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default StartGame;