import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div>
           
            {
                props[0].location.pathname != '/prijava' ?
                    
                    <>
                        {/* <Header {...props} /> */}
                        {/* <Nav {...props} /> */}
                        <Wrapped {...props} />
                        {/* <Footer {...props} /> */}
                    </>
                    :
                    <>
                        <Wrapped {...props} />
                    </>

            }

        </div>
    );
};

export default DefaultLayout;