import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux'
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,

} from 'reactstrap';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        // console.log(this.props.children);
        if (this.props.children && this.props.children[0] && this.props.children[0].postanskiBroj) {
            return (
                // <>
                // { this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null } 
                //     <Input className="field-wrap" /*className={this.props.error ? "required" : ""} */type='select' onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
                // </>
                <div className="form-field">
                    {this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null}

                    <Dropdown disabled={this.props.disabled} className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                        <DropdownToggle nav caret>
                            {
                                this.props.value ? this.props.children.find(o => o.postanskiBroj + " - " + o.zip === this.props.value) ? this.props.children.find(o => o.postanskiBroj + " - " + o.zip === this.props.value).postanskiBroj + " - " + this.props.children.find(o => o.postanskiBroj + " - " + o.zip === this.props.value).zip : this.props.placeholder : this.props.placeholder
                            }
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-animation">
                            {
                                this.props.children && this.props.children.map((children) => {
                                    const posta = children.postanskiBroj + " - " + children.zip;
                                    if (this.props.city && this.props.city == children.city)
                                        return (
                                            <DropdownItem onClick={() => this.props.onChange(posta)}>{children.postanskiBroj} - {children.zip}</DropdownItem>
                                        )
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        } else {
            return (
                // <>
                // { this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null } 
                //     <Input className="field-wrap" /*className={this.props.error ? "required" : ""} */type='select' onChange={this.props.onChange} value={this.props.value} placeholder={this.props.placeholder} />
                // </>
                <div className="form-field">
                    {this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null}

                    <Dropdown disabled={this.props.disabled} className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                        <DropdownToggle nav caret>
                            {
                                this.props.value ? this.props.children.find(o => o.city === this.props.value) ? this.props.children.find(o => o.city === this.props.value).city : this.props.placeholder : this.props.placeholder
                            }
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-animation">
                            {
                                this.props.children && this.props.children.map((children) => {
                                    // if (children.props)
                                    return (
                                        <DropdownItem onClick={() => this.props.onChange(children.city)}>{children.city}</DropdownItem>
                                    )
                                })
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        }

    }
}
const selector = formValueSelector('registerForm');

export default connect(state => {
    return {
        city: selector(state, 'city'),


    }


}, {


})(Select);
// export default Select;