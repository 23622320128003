import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";

import Text from "./fields/text";
import Select from "./fields/select";
import Textarea from "./fields/textarea";
import Check from "./fields/check";
import Isvg from "react-inlinesvg";
import button from "../../assets/button.png";

import { API_ENDPOINT } from "../../constants";
import Cola from '../../assets/cola.png'
import { Container, Row, Col } from "reactstrap";
import logo from '../../assets/logo.png';
function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}
const required = (value) => (value ? undefined : "Required");

const renderSelectField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  children,
  additionalAction,
  scope,
  value,
  disabled,
}) => (
  <Select
    placeholder={placeholder}
    disabled={disabled}
    label={label}
    errorText={touched && error}
    error={touched && error}
    additionalAction={additionalAction}
    scope={scope}
    value={value}
    {...input}
    children={children}
  />
);

const renderTextField = ({
  input,
  label,
  placeholder,
  meta: { touched, error },
  value,
  type,
}) => (
  <Text
    placeholder={placeholder}
    errorText={touched && error}
    label={label}
    error={touched && error}
    type={type}
    value={value}
    {...input}
  />
);

const renderTextareaField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
}) => (
  <Textarea
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    {...input}
  />
);

const renderCheckField = ({
  input,
  placeholder,
  label,
  meta: { touched, error },
  value,
}) => (
  <Check
    placeholder={placeholder}
    label={label}
    errorText={touched && error}
    error={touched && error}
    value={value}
    {...input}
  />
);

class form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: [],
    };
  }

  componentDidMount() {
    if (this.props.initialValues && this.props.initialValues.idNumber) {
      fetch(API_ENDPOINT + "/data/userInput/validate-prize", {
        method: "POST",
        headers: {
          /*'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,*/
          "content-type": "application/json",
        },
        body: JSON.stringify({ filter: this.props.initialValues.idNumber }),
      })
        .then(parseJSON)
        .then(({ result, status }) => {
          if (result.condition) {
            this.setState(
              {
                condition: result.condition,
                sizes: result.sizes,
              });
          } else {
            this.setState({
              condition: false
            })
          }
        });
    }
  }

  render() {
    const { handleSubmit, cities, posts } = this.props;
    return (
      <form onSubmit={handleSubmit} className="register-form">
        <Row className="register-form-container">
          <div className="register-form-input">
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="firstName"
                  component={renderTextField}
                  placeholder={`Ime:`}
                // validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="lastName"
                  component={renderTextField}
                  placeholder="Prezime:"
                // validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="idNumber"
                  component={renderTextField}
                  placeholder="ID broj nagrade:"
                  // validate={[required]}
                  onChange={(val) => {
                    fetch(API_ENDPOINT + "/data/userInput/validate-prize", {
                      method: "POST",
                      headers: {
                        /*'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,*/
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({ filter: val }),
                    })
                      .then(parseJSON)
                      .then(({ result, status }) => {
                        if (result.condition) {
                          this.setState(
                            {
                              condition: result.condition,
                              sizes: result.sizes
                            });
                        } else {
                          this.setState({
                            condition: false
                          })
                        }
                      });
                  }}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="phone"
                  component={renderTextField}
                  type="number"
                  placeholder="Broj telefona:"
                // validate={[required]}
                ></Field>
              </div>
            </Col>
            <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="email"
                  component={renderTextField}
                  placeholder="E-mail adresa:"
                // validate={[required]}
                ></Field>
              </div>
            </Col>
            {
              !this.state.condition || (this.state.condition && this.state.condition !== "Glovo vaucer") ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="idCard"
                      component={renderTextField}
                      placeholder="Br. Lične karte/Pasoša:"
                    // validate={[required]}
                    ></Field>
                  </div>
                </Col>
                :
                null
            }
            {
              !this.state.condition || (this.state.condition && this.state.condition !== "Glovo vaucer") ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="address"
                      component={renderTextField}
                      placeholder="Adresa:"
                    // validate={[required]}
                    ></Field>
                  </div>
                </Col>
                :
                null
            }
            {
              !this.state.condition || (this.state.condition && this.state.condition !== "Glovo vaucer") ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="zipCode"
                      component={renderTextField}
                      placeholder="Poštanski broj:"
                    // validate={[required]}
                    ></Field>
                  </div>
                </Col>
                :
                null
            }

            {
              this.state.condition && (this.state.condition === 'Beli dres' || this.state.condition === 'Crveni dres') ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="size"
                      component={renderSelectField}
                      placeholder="Veličina dresa:"
                      children={this.state.sizes}
                    // validate={[required]}
                    ></Field>
                  </div>
                </Col>
                :
                null
            }
            {
              this.state.condition && this.state.condition === 'TV-JBL' ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="ssn"
                      component={renderTextField}
                      placeholder="JMBG:"
                    // validate={[required]}
                    ></Field>
                  </div>
                </Col>
                :
                null
            }


            {
              // this.state.condition && this.state.condition == 'venac' ?
              //   <>
              //     <Col lg='12'>
              //       <p style={{ fontSize: 20, color: 'white' }}>
              //         molimo vas koristite kvačice Š,Ć,Č...
              //       </p>
              //     </Col>
              //     <Col lg="12">
              //       <div className="field-wrap">
              //         <Field
              //           name="venacIme"
              //           component={renderTextField}
              //           placeholder="Prezime za venac"
              //         // validate={[required]}
              //         ></Field>
              //       </div>
              //     </Col>

              //   </>
              //   :
              //   null
            }

            {/* DROPDOWN */}
            {/* <Col lg="12">
              <div className="field-wrap">
                <Field
                  name="city"
                  component={renderSelectField}
                  placeholder="Grad u kojem se pošta nalazi:"
                  children={cities}
                // validate={[required]}
                ></Field>
              </div>
            </Col>
            {
              typeof window !== 'undefined' && window.innerWidth > 768 ?
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="post"
                      component={renderSelectField}
                      placeholder={`Odabrana poslovnica JP "Pošte Srbije":`}
                      children={posts}
                    ></Field>
                  </div>
                </Col>
                :
                <Col lg="12">
                  <div className="field-wrap">
                    <Field
                      name="post"
                      component={renderSelectField}
                      placeholder={`Odabrana poslovnica JP "Pošte Srbije":`}
                      children={posts}
                    ></Field>
                  </div>
                </Col>
            } */}
          </div>



          {/*______________________________________________________________________________________________________________________*/}
          <Col lg="12">
            <div className="terms">
              <div className="terms-checkbox">
                <Field
                  name="terms"
                  component={renderCheckField}
                // validate={[required]}
                ></Field>
                <span>Saglasan sam sa uslovima obrade podataka o ličnosti</span>
              </div>
              <div>
                {typeof window != "undefined" ? (
                  <a
                    href={`${window.location.origin}` + "/terms"}
                    target="_blank"
                  >
                    Uslovi obrade podataka
                  </a>
                ) : null}
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="confirm">
              <p>Nakon potvrde o poručivanju proverite spam/junk e-mail foldere.</p>
            </div>
          </Col>

          <Col lg="12" className="btn-wrap">
            <button type="submit">
              Potvrdi porudžbinu
              <img src={Cola} />
            </button>
          </Col>
          <img src={logo} className="logo" alt='Logo' />
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: "registerForm", // a unique identifier for this form
})(form);
