import React, { Component } from "react";
import Page from '../containers/page';
import congratulationsBacground from '../assets/congratulations-background.jpg'
import logo from '../assets/logo-crveni.png';


class Thank extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="congratulations-page">
                <div className="background-image">
                    <img src={congratulationsBacground} />
                </div>
                <div className="congratulations-container">
                    <div className="congratulations-card">
                        <h1>Cestitamo!</h1>
                        <p>Uneli ste podatke i uspesno porucili svoj poklon.</p>
                    </div>
                    <div className="congratulations-info">
                        <p>Za sve dodatne informacije posetite sajt <a href="www.coca-cola.rs" target="_blank">www.coca-cola.rs.</a></p>
                    </div>
                    <img src={logo} className="logo" />
                </div>
            </div>
        )
    }
}

export default Page(Thank);