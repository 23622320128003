import React from 'react';
import Cola from '../../assets/cola.png'
import obrnutaLopta from '../../assets/obrnuta_lopta.svg'

const ColaConfirmPage = ({
    confirmData,
    saglasnost,
    disableButton,
    error,
    overlay,
    onSaglasnostChange,
    onSubmit,
    onEdit,
    onCloseError
}) => {
    return (
        <div className="cola-confirm-page">
            <div className='container'>
                <div className="colaContent">
                    <div className="heading-cola">
                        Potvrđivanjem porudžbine uneli ste dole navedene podatke u našu bazu. Molimo vas da proverite tačnost podataka, u suprotnom organizator zadržava pravo da ne isporuči poklone.
                        <br />
                        Nakon potvrde unetih podataka klikom na dugme "Završi porudžbinu" potvrđujete validnost unetih podataka i možete preuzeti potvrdu poručenog poklona na sledećoj stranici.
                    </div>
                    <ul>
                        <li>Ime: {confirmData.firstName}</li>
                        <li>Prezime: {confirmData.lastName}</li>
                        <li>ID Broj nagrade: {confirmData.idNumber}</li>
                        <li>Broj telefona: {confirmData.phone}</li>
                        <li>E-mail adresa: {confirmData.email}</li>
                        {confirmData.idCard && <li>Br. Lične karte/Pasoša: {confirmData.idCard}</li>}
                        {confirmData.address && <li>Adresa za isporuku nagrade: {confirmData.address}</li>}
                        {confirmData.zipCode && <li>Poštanski broj adrese za isporuku: {confirmData.zipCode}</li>}
                        {confirmData.size && <li>Veličina dresa: {confirmData.size}</li>}
                        {confirmData.ssn && <li>JMBG: {confirmData.ssn}</li>}
                    </ul>
                    <div className="confirm-check">
                        <input
                            type="checkbox"
                            id="myCheckbox"
                            checked={saglasnost}
                            onChange={onSaglasnostChange}
                        />
                        <span>Potvrđujem tačnost navedenih podataka</span>
                    </div>
                    <div className="colaButtons">
                        {saglasnost && (
                            <button
                                disabled={disableButton}
                                onClick={onSubmit}
                                className="buttonCola"
                            >
                                Završi porudžbinu <img src={Cola} alt="Cola" />
                            </button>
                        )}
                        <button onClick={onEdit} className="buttonCola">
                            Izmeni podatke <img src={Cola} alt="Cola" />
                        </button>
                    </div>
                </div>
                <div className={error ? 'error-modal open' : 'error-modal'}>
                    <span onClick={onCloseError} className="close">
                        x
                    </span>
                    <h1>Warnning!</h1>
                    <p>{error}</p>
                </div>

            </div>
            <div className={overlay ? 'overlay open' : 'overlay'} onClick={onCloseError}></div>
            <img src={obrnutaLopta} className='background-image' alt='Background' />
        </div>
    );
};

export default ColaConfirmPage;