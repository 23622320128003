import React from 'react';
import logo from '../../assets/logo-crveni.png';
import GenerateImage from './generateImage';
import Cola from '../../assets/cola.png'
import withQr from '../../assets/with-qr.png'
import backgroundDecor from '../../assets/lopta.svg'
import ISvg from 'react-inlinesvg'
import qrMobile from '../../assets/qrMobile.png'

const CongratulationsPage = ({ onClick, loading }) => {
    return (
        <div className="congratulations-page">
            <div className="background-image">
                {
                    window?.innerWidth < 578 ?
                        <img src={qrMobile} />
                        :
                        <img src={withQr} />
                }
            </div>
            <ISvg className='congratulations-decor' src={backgroundDecor} />
            <div className="congratulations-card">
                <h1>Čestitamo!</h1>
                <p>Uneli ste podatke i uspešno poručili svoj poklon.</p>
            </div>
            <div className="congratulations-info">
                <p>Napomene vezane za preuzimanje poklona i rokove pronađite na potvrdi koju možete preuzeti klikom na dugme ispod.</p>
                <p>Za sve dodatne informacije posetite sajt <a href="www.coca-cola.rs" target="_blank">www.coca-cola.rs.</a></p>
            </div>
            <button className='download-image-button' onClick={onClick}>{loading ? <div className='loader'></div> : "Preuzmi i sačuvaj potvrdu"}<img src={Cola} alt="Cola" /></button>
            <img src={logo} className="logo" />

        </div>
    )
}

export default CongratulationsPage;