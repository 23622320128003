

import React, { Component } from 'react';
import RegistarForm from '../../components/forms/registerForm';
import logo from '../../assets/logo.png';
import headerImage from '../../assets/header-image.jpg'
import Cola from '../../assets/cola.png'
import { API_ENDPOINT, PRODUCTION_ENV } from '../../constants';
import StartGameComponent from './startGameComponent';
import obrnutaLopta from '../../assets/obrnuta_lopta.svg'
import textDecoration from '../../assets/linije_2.svg'
import GenerateImage from './generateImage';
import ColaConfirmPage from './colaConfirmPage';
import { toPng } from 'html-to-image';
import * as htmltoimage from 'html-to-image';
import CongratulationsPage from './congratulationsPage';

function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);
        // this.imageRef = React.createRef();
        this.state = {
            ...props.initialData,
            errorModal: false,
            overlay: false,
            saglasnost: false,
            disableButton: false,
            posts: [],
            cities: [],
            isProductionEnvironment: PRODUCTION_ENV,
            loading: false,
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps[0].location.pathname != this.props[0].location.pathname) {
    //         this.setState({ congratsPageKey: Date.now() });
    //     }
    // }


    userInput = async (data) => {
        let condition = false
        if (data.idNumber)
            await fetch(API_ENDPOINT + "/data/userInput/validate-prize", {
                method: "POST",
                headers: {
                    /*'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,*/
                    "content-type": "application/json",
                },
                body: JSON.stringify({ filter: data.idNumber }),
            }).then(parseJSON).then(({ result, status }) => {
                if (result.condition) {
                    condition = result.condition

                }
            });

            // console.log(condition, data);
        if (!data.terms) {
            this.setState({
                error: 'Morate prihvatiti uslove obrade podataka o ličnosti',
                overlay: true
            })
        } else if (!data.firstName || data.firstName == '') {
            this.setState({
                error: 'Unesite ime',
                overlay: true
            })
        } else if (!data.lastName || data.lastName == '') {
            this.setState({
                error: 'Unesite prezime',
                overlay: true
            })
            // } else if (!data.idCard || data.idCard == '') {
            //     this.setState({
            //         error: 'Unesite broj lične karte/pasoša'
            //     })
        } else if (!data.phone || data.phone == '') {
            this.setState({
                error: 'Unesite broj telefona',
                overlay: true
            })
        } else if (data.phone && (data.phone[0] !== '0' || data.phone[1] !== '6')) {
            this.setState({
                error: "Broj telefona mora poceti sa brojevima 06.",
                overlay: true
            })
        } else if (!data.email || data.email == '') {
            this.setState({
                error: 'Unesite e-mail adresu',
                overlay: true
            })
        } else if (!data.idNumber || data.idNumber == '') {
            this.setState({
                error: 'Unesite ID broj nagrade',
                overlay: true
            })
        } else if ( condition && (condition === 'Beli dres' || condition === 'Crveni dres') && (!data.size || data.size == '')) {
            this.setState({
                error: 'Unesite veličinu dresa',
                overlay: true
            })
        }else if (condition && condition === 'TV-JBL' && (!data.ssn || data.ssn == '')) {
            this.setState({
                error: 'Unesite JMBG',
                overlay: true
            })
        }
        else if ((!data.idCard || data.idCard == '') && (!condition || (condition && condition !== 'Glovo vaucer'))) {
            this.setState({
                error: 'Unesite broj lične karte/pasoša',
                overlay: true
            })
        } else if ((!data.address || data.address == '') && (!condition || (condition && condition !== 'Glovo vaucer'))) {
            this.setState({
                error: 'Unesite adresu za isporuku nagrade',
                overlay: true
            })
        } else if ((!data.zipCode || data.zipCode == '') && (!condition || (condition && condition !== 'Glovo vaucer'))) {
            this.setState({
                error: 'Unesite poštanski broj',
                overlay: true
            })
        }
        else {
            // console.log(data);
            if(condition && condition === 'Glovo vaucer'){
                data.idCard = '';
                data.address = '';
                data.zipCode = '';
            }
            if(condition && !(condition === 'Beli dres' || condition === 'Crveni dres')){
                data.size = '';
            }
            // console.log(condition, data);
            this.setState({
                confirmData: data,
                showConfirm: true,
                saglasnost: false
            })
        }
    }

    sendData = () => {
        if (!this.state.saglasnost) {
            this.setState({
                error: 'Morate potvrditi tačnost unetih podataka',
                overlay: true
            })
        } else {
            fetch(API_ENDPOINT + '/data/userInput/new', {
                method: 'POST',
                headers: {
                    /*'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,*/
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ ...this.state.confirmData, saglasnost: this.state.saglasnost })
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error, overlay: true, disableButton: false })
                } else {
                    // this.htmlToImageConvert();
                    this.setState({ showCongratulations: true, showConfirm: false });
                }
            })
        }

    }

    handleSaglasnostChange = (e) => {
        this.setState({
            saglasnost: e.target.checked
        });
    };

    handleSubmit = () => {
        this.setState({
            disableButton: true
        }, () => {
            this.sendData();

        });
    };

    handleEdit = () => {
        this.setState({
            showConfirm: false,
            saglasnost: false
        });
    };

    handleCloseError = () => {
        this.setState({
            error: false,
            overlay: false
        });
    };

    htmlToImageConvert = () => {
        this.setState({ loading: true });
        let imageNode = document.getElementById('image');
        if (imageNode) {
            imageNode.style.backgroundColor = '#e31e2a'

            htmltoimage.toPng(imageNode)
                .then((dataUrl) => {
                    let base64str = dataUrl.substring(dataUrl.indexOf(',') + 1)
                    var decoded = atob(base64str);

                    console.log("FileSize: " + decoded.length);
                    if (decoded.length < 90000) {
                        this.htmlToImageConvert();
                        return
                    }
                    const link = document.createElement("a");
                    link.download = `potvrda_${this.state.confirmData?.idNumber}.png`;
                    link.href = dataUrl;
                    link.click();
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };



    render() {
        return (
            this.state.isProductionEnvironment ?
                (
                    this.state.showCongratulations ?
                        <>
                            <GenerateImage confirmData={this.state.confirmData} />
                            <CongratulationsPage confirmData={this.state.confirmData} onClick={this.htmlToImageConvert} loading={this.state.loading} />
                        </>

                        :
                        this.state.showConfirm ?
                            <ColaConfirmPage
                                confirmData={this.state.confirmData}
                                saglasnost={this.state.saglasnost}
                                disableButton={this.state.disableButton}
                                error={this.state.error}
                                overlay={this.state.overlay}
                                onSaglasnostChange={this.handleSaglasnostChange}
                                onSubmit={this.handleSubmit}
                                onEdit={this.handleEdit}
                                onCloseError={this.handleCloseError}
                            />
                            :
                            (
                                <div className='home-page'>
                                    <div className='home-page-container'>
                                        <div className='heading'>
                                            <h1>
                                                Dobro došli na Coca-Cola
                                                <br />
                                                stranicu za poručivanje poklona!
                                            </h1>
                                            <h1>
                                                Čestitamo!
                                            </h1>
                                            <p className='help-text'>Molimo vas da tačno popunite polja sa ličnim podacima osobe koja preuzima poklon.</p>
                                        </div>
                                        <RegistarForm initialValues={this.state.confirmData} posts={this.state.posts} cities={this.state.cities} onSubmit={(data) => this.userInput(data)} />
                                    </div>
                                    <div className={this.state.error ? 'error-modal open' : 'error-modal'}>
                                        <span onClick={() => {
                                            this.setState({
                                                error: false,
                                                overlay: false
                                            })
                                        }} className="close">x</span>
                                        <h1>Warnning!</h1>
                                        <p>{this.state.error}</p>
                                    </div>
                                    <div className={this.state.error ? 'overlay open' : 'overlay'} onClick={() => {
                                        this.setState({
                                            error: false,
                                            overlay: false
                                        })
                                    }}></div>
                                </div>
                            )
                )
                :
                (
                    <StartGameComponent />
                    // <GenerateImage confirmData={this.state.confirmData} onClick={this.htmlToImageConvert} />
                )
        );
    }
}

export default HomePage;