
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_ENDPOINT } from '../constants';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap';

export class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenu: false
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                

            </>
        )
    }
}

export default Nav;