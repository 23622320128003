import React, { Component } from "react";
import Page from '../containers/page';
import Cola from '../assets/cola.png'
import headerImage from '../assets/header-image.jpg'

class PopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="COLA">
                <img src={headerImage} className="headerImage" />
                <div className="cola">

                    <div className="colaContent">
                        <div className="heading-cola">
                            Potvrđivanjem porudžbine uneli ste dole navedene podatke u našu bazu. Molimo vas proverite tačnost podataka, u suprotnom organizator zadržava pravo da ne isporuči poklone. Nakon potvrde unetih podataka klikom na dugme "Završi porudzbinu" potvrđujete validnost unetih podataka i možete očekivati mejl potvrde sa podacima o poružbini na e-mail adresi koju ovde navodite.
                        </div>
                        <div className="heading-cola">
                            Obavezno proverite i foldere neželjene pošte (span/junk) e-mail foldere
                        </div>
                        <ul>
                            <li>
                                Ime: Petar
                            </li>
                            <li>
                                Prezime: Peric
                            </li>
                            <li>
                                Br. Lične karte/Pasoša: 111222333
                            </li>
                            <li>
                                Br. Telefona: 069111222333
                            </li>
                            <li>
                                I-mejl adresa: ppera@gmail.com
                            </li>
                            <li>
                                ID Broj poklona: 123456789
                            </li>
                            <li>
                                Grad: Novi Sad
                            </li>
                            <li>
                                Odabrana poslovnica JP "Poste Srbije": Glavna, Zemun
                            </li>
                        </ul>
                        <div className="confirm-check">
                            <input type="checkbox" id="myCheckbox" />
                            <span>Potvrdjujem tačnost navedenih podataka</span>
                        </div>
                        <div className="colaButtons">
                            <button className="buttonCola">Završi porudžbinu <img src={Cola} /></button>
                            <button className="buttonCola">Izmeni podatke <img src={Cola} /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Page(PopUp);