
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';



class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        // console.log(this.props)
        //console.log(generateAlias("ćčććasd"))
        return (
            <>
                {this.props.label ? <label className={this.props.error ? "required" : ""}>{this.props.label}</label> : null}
                <input className={this.props.error ? "required" : ""} type={'text'} onChange={(e) => {
                    let value = e.target.value

                    if (this.props.placeholder == 'Broj telefona:') {
                        if(e.target.value.length > 10){
                            value = this.props.value
                        }
                        value = value.replace(/[^0-9]/g, '').replace('00381', '0').replace('381', '0')
                        
                    }
                    if (this.props.placeholder == 'Br. Lične karte/Pasoša:') {
                        if(e.target.value.length > 9){
                            value = this.props.value
                        }
                        value = value.replace(/[^0-9]/g, '')
                        
                    }
                    this.props.onChange(value)
                }}
                    value={this.props.value} placeholder={this.props.placeholder} />
            </>
        );
    }
}

export default Text;