import React from 'react';
import textDecoration from '../../assets/linije_2.svg'


const GenerateImage = ({ confirmData }) => {
    return (
        <div className='generate-image-element' id='confirmation'>
            <div className='confirmation' id="image">
                <div className='confirmation-header'>
                    <h1>
                        Potvrda o
                        <br />
                        poručenom poklonu
                    </h1>
                    <h3>
                        Uneli ste sledeće podatke:
                    </h3>
                </div>
                <div className='confirmation-content'>
                    <p>Ime: {confirmData?.firstName}</p>
                    <p>Prezime: {confirmData?.lastName}</p>
                    <p>ID broj nagrade: {confirmData?.idNumber}</p>
                    <p>Br. telefona: {confirmData?.phone}</p>
                    <p>E-mail adresa: {confirmData?.email}</p>

                    {confirmData?.idCard && <p>Br. lične karte/Pasoša: {confirmData?.idCard}</p>}
                    {confirmData?.address && <p>Adresa za isporuku nagrade: {confirmData?.address}</p>}
                    {confirmData?.zipCode && <p>Poštanski broj adrese za isporuku: {confirmData?.zipCode}</p>}
                    {confirmData?.size && <p>Veličina dresa: {confirmData?.size}</p>}
                    {confirmData?.ssn && <p>JMBG: {confirmData?.ssn}</p>}
                </div>
                <div className='confirmation-description'>
                    <div>
                        <p>VAŽNA NAPOMENA</p>
                        <p>
                            Potrebno je da do <span>03.09.2024.</span> godine čuvate dobitne čepove boca ili etikete sa dobitnim kodovima, radi eventualne kontrole validnosti.
                            <br />
                            Sve nagrade izuzev vaučera za Glovo će biti isporučene na kućnu adresu, odnosno, na adresu unetu
                            u podatke, najkasnije 60 dana nakon unošenja podataka, odnosno, poručivanja poklona na
                            <span> porucipoklon.rs.</span>
                        </p>
                    </div>
                    <p>
                        Ukoliko lice čiji su podaci dostavijeni ne bude prisutno na adresi, pošiljka će biti vraćena u neku od
                        objekata „Pošta Srbije", takođe, biće dostavijen izveštaj o prispeću pošiljke sa informacijom gde se može preuzeti poklon u narednih 5 dana. Nakon isteka tih 5 dana, dobitnik gubi pravo na nagradu.
                    </p>
                    <p>
                        U slučaju da ste osvojili Glovo vaučer, u roku od 15 dana će na Vaš email stići email sa instrukcijama za korišćenje Glovo vaučera od <span>5.000 RSD.</span>
                    </p>
                </div>
                <img className='text-decoration' src={textDecoration} />
            </div>
        </div>
    )
}

export default GenerateImage;